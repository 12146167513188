.URLloader {
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border-radius: 0.5rem;
}

.URLloader span {
  width: 100%;
}

.URLloader span::before {
  content: '';
  position: absolute;
  top: 2.5%;
  bottom: 2.5%;
  width: 97%;
  height: 0.5rem;
  background: #7de2f9;
  border: 1px solid #43b6cf;
  animation: y 2s ease-in-out infinite alternate;
  border-radius: 0.25rem;
  margin-left: 1.5%;
  margin-right: 1.5%;
  z-index: 10;
}

.loader-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: -webkit-fill-available;
  margin: 1rem;
  max-width: 3rem;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes y {
  to {
    transform: translateY(-97.5%);
    top: 97.5%;
  }
}

/* Glass Morphism animation */
.card-container {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 1rem;
}

.dinamic-card {
  height: 100%;
  width: 2.5rem;
  border-right: 1px solid #e5e7eb;
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  position: absolute;
  top: 0;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
}

.one {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  border-image: linear-gradient(#e8edf5, #ffffff) 30;
  animation: float-one 12s ease-in-out infinite;
  left: -1rem;
  z-index: 3;
}

.two {
  background: linear-gradient(
    180deg,
    rgba(237, 241, 248, 0.8) 0%,
    rgba(237, 241, 248, 0.2) 100%
  );
  border-image: linear-gradient(#e8edf5, #ffffff) 30;
  animation: float-two 16s ease-in-out infinite;
  left: -0.5rem;
  z-index: 2;
}

.three {
  background: linear-gradient(
    180deg,
    rgba(192, 202, 219, 0.2) 0%,
    rgba(192, 202, 219, 0.8) 100%
  );
  border-image: linear-gradient(#e8edf5, #ffffff) 30;
  animation: float-three 20s ease-in-out infinite;
  left: -1rem;
  z-index: 1;
  opacity: 0.8;
}

@keyframes float-one {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(-1.25rem);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes float-two {
  0% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(0.25rem);
  }
  50% {
    transform: translateX(0rem);
  }
  75% {
    transform: translateX(-0.25rem);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes float-three {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(1.25rem);
  }
  100% {
    transform: translateX(0px);
  }
}

canvas {
  display: block;
  width: 100%;
  height: 100%;
}

.productImgContainer {
  transform: perspective(35cm) rotateX(5deg) rotateY(0deg);
  animation: perspective 16s ease infinite;
}

.productImgContainer2 {
  transform: perspective(35cm) rotateX(5deg) rotateY(0deg);
  animation: perspective2 16s ease infinite;
}

@keyframes perspective {
  0% {
    transform: perspective(35cm) rotateX(5deg) rotateY(0deg);
  }
  50% {
    transform: perspective(35cm) rotateX(5deg) rotateY(-20deg);
  }
  100% {
    transform: perspective(35cm) rotateX(5deg) rotateY(0deg);
  }
}

@keyframes perspective2 {
  0% {
    transform: perspective(35cm) rotateX(5deg) rotateY(0deg);
  }
  50% {
    transform: perspective(35cm) rotateX(5deg) rotateY(-10deg);
  }
  100% {
    transform: perspective(35cm) rotateX(5deg) rotateY(0deg);
  }
}

.productImg {
  position: relative;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 1rem;
  max-height: 600px;
}

.imgLeft {
  animation: float1 16s ease infinite;
}

.imgRight {
  animation: float2 16s ease infinite;
}

@keyframes float1 {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes float2 {
  0% {
    transform: translatey(-20px);
  }
  50% {
    transform: translatey(0px);
  }
  100% {
    transform: translatey(-20px);
  }
}

@media only screen and (max-width: 1500px) {
  .productImg {
    max-height: 450px;
  }
}
